import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AboutHeroWrapper } from "./styles";
import Heading from "views/components/shared/Heading";

export default function AboutHero() {
  return (
    <AboutHeroWrapper>
      <Container>
        <Row>
          <Col>
            <Heading
              className="h1 text-white ps-0 mt-5 pt-5 pt-md-0 mt-md-0"
              level={2}
            >
              We boost profits using AI
            </Heading>
          </Col>
        </Row>
      </Container>
    </AboutHeroWrapper>
  );
}
