import RoundIcon from "assets/Icons/RoundIcon";

// data.js
export const sectionsData = [
  {
    id: 1,
    icon: <RoundIcon />,
    paragraph:
      "We aren’t your typical consultancy. You’re not OK with failing and neither are we.",
  },
  {
    id: 2,
    icon: <RoundIcon />,
    paragraph:
      "We believe in action. We take complex ideas, apply AI, and turn them into simple goals."
  },
  {
    id: 3,
    icon: <RoundIcon />,
    paragraph:
      "We’re operators & pharmacists. We know what it's like to work on the front line and understand the market challenges.",
  },
  {
    id: 4,
    icon: <RoundIcon />,
    paragraph:
      "We’re not judging your entrepreneurial experience. We’re interested in you, your vision and your drive to make it happen.",
  },
];
