// src/views/pages/About.js

import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import AboutImg from "assets/Images/founder_img.jpg";
import Paragraph from "views/components/shared/Paragraph";
import { FounderWrapper } from "./styles";
import Heading from "views/components/shared/Heading";

const Founder = () => {
  return (
    <FounderWrapper>
      <Container>
        <Row className="justify-content-between">
          <Col md={6}>
            <Image src={AboutImg} fluid className="w-100 pe-md-3 pe-0" />
          </Col>
          <Col md={6}>
            <div className="ps-md-4 ps-0">
              <Heading level={2} className="h2XMedium">
                Founders<span>.</span> Operators<span>.</span> Pharmacist
                <span className="aqua">.</span>
              </Heading>
              <Paragraph className="lgSmall">
              The Taskgo founder, Adam, never set out to start a AI digital consultancy firm. He is a pharmacist, who after having completed an MBA, became an operator. This led to running and managing businesses to become successful. He knows how hard it is to grow a business and has learnt a lot doing so – the good and the bad. He started Taskgo because he wanted to lead in the forefront of AI developments. All business markets are underserved, when it comes to applying AI & boosting profits. His experience as a superintendent pharmacist within Ireland was evident for this.
              </Paragraph>
              <Paragraph className="lgSmall">
                What makes it more frustrating, is trying to grow a retail/online business whilst being overburdened with essential administrative tasks. For this reason, he developed Ireland’s first industry specific AI tool aimed at making pharmacy administration seamless and stress free. Taskgo prides itself on taking businesses to the next level.
              </Paragraph>
            </div>
          </Col>
        </Row>
      </Container>
    </FounderWrapper>
  );
};

export default Founder;
