export const data = [
	{
		number: "1.",
		title: "Comprehensive Consultation",
		description:
			"We sit down and understand your vision. We look at the numbers and see how you’re progressing to achieve your vision. If you have a specific goal/issue in mind, then we’ll go through this at the very first meeting.",
	},
	{
		number: "2.",
		title: "AI Strategy",
		description:
			"We dig deeper and agree on the critical success factors. We’ll validate the direction, time frame, resources and environment needed in order to achieve your aim.",
	},
	{
		number: "3.",
		title: "Tactical Planning",
		description:
			"We’ll plan with conviction and clarity. The strategy is broken into action points and monitored through KPIs. All being well, once a plan is in place, we move forward quickly.",
	},
	{
		number: "4.",
		title: "Successful Execution",
		description: "We put the plan in motion and execute."
	},
];
